<template>
  <v-app style="background-color:#f8f9fb" >

      <!-- Diálogo de progresso -->
      <v-dialog v-model="showProgress" persistent max-width="300">
        <v-card>
          <v-card-title>
            Baixando arquivo...
          </v-card-title>
          <v-card-text>
            <!-- Coloque aqui qualquer indicador de progresso que você deseja mostrar -->
            <v-progress-linear indeterminate color="primary"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>


    <v-container >
      <v-col cols="12" >
        <filtro-component :opcoes="opcoes" :modos="modos" :tipos="tipos" :calendar="'ano'" :produtos="tipos_funerarios" 
        @filtrar="filterData" :title_opcao="'Situação'"
          ></filtro-component>
      </v-col>
    </v-container>



    <v-container style="background-color:#eee"  
    id="obito"
    fluid
    tag="section">
    
    <v-row>
  
      <v-col
      cols="12"
       sm="6"
       lg="3"
     >
     <base-material-stats-card
        color="blue"
        icon="mdi-flag"
        subIcon="mdi-grave-stone"
        subIconE="mdi-coffin"
        subIconColor="red"
        title="Alagoas"
        :subTextE="'Funerária Previda : ' + this.funeraria_previda"
        subIconColorE="blue"
        sub-icon-color="black"
        :value="this.obitos_al.toString()"
        :valuex="false"
        :sub-text="'Cemitérios Grupo Parque : ' + (this.memorial_parque_maceio + this.parque_das_flores +
          this.cemiterio_previda + this.parque_agreste)"
        :show-download-button-one="true"
         :show-download-button-two="true"
        @download-click="download_xls('PARQUE DAS FLORES ,MEMORIAL PARQUE MACEIÓ, CEMITERIO PREVIDA, PARQUE DO AGRESTE', 'Cemiterio')"
        @download-click-two="download_xls('PREVIDA', 'Plano')"

        />
      
     </v-col>
  
     <v-col
     cols="12"
      sm="6"
      lg="3"
    >
     
      <base-material-stats-card
        color="green"
        icon="mdi-map"
        title=" Maceió (Nº)"
        subIcon="mdi-grave-stone"
        subIconColor="red"
        sub-icon-color="white"
        :value='this.obitos_m.toString()'
        :valuex='false'
        :subTextE="'Cemitérios Públicos : ' + (this.obitos_m - (this.memorial_parque_maceio + this.parque_das_flores))"
        subIconColorE="blue"
        subIconE="mdi-grave-stone"
        :sub-text="'Cemitérios Grupo Parque : ' + (this.memorial_parque_maceio + this.parque_das_flores)"
             :show-download-button-one="true"
             @download-click="download_xls('PARQUE DAS FLORES,MEMORIAL PARQUE MACEIÓ', 'Cemiterio')"
             />
     
    </v-col>

    <v-col
    cols="12"
     sm="6"
     lg="3">
    
     <base-material-stats-card
       color="orange"
       icon="mdi-map"
       title="Arapiraca (Nº)"
       sub-icon-color="blue"
       subIcon="mdi-grave-stone"
       subIconColor="red"
       :subTextE="'Cemitérios Públicos : ' + (this.obitos_a - this.cemiterio_previda)"
       subIconColorE="blue"
        subIconE="mdi-grave-stone"      
       :value='this.obitos_a.toString()'
       :valuex='false'
       :sub-text="'Cemitérios Grupo Parque : ' + this.cemiterio_previda.toString()"
            :show-download-button-one="true"
            @download-click="download_xls('CEMITERIO PREVIDA', 'Cemiterio')"
            />
    
   </v-col>

     <v-col
    cols="12"
     sm="6"
     lg="3"
   >
    
     <base-material-stats-card
       color="purple"
       icon="mdi-map"
       title="Palmeira (Nº)"
       sub-icon-color="blue"
       subIcon="mdi-grave-stone"
       subIconColor="red"
       :subTextE="'Cemitérios Públicos : ' + (this.obitos_p - this.parque_agreste)"
        subIconColorE="blue"
        subIconE="mdi-grave-stone"
       :value='this.obitos_p.toString()'
       :valuex='false'
       :sub-text="'Cemitérios Grupo Parque : ' + this.parque_agreste.toString()"
            :show-download-button-one="true"
        @download-click="download_xls('PARQUE DO AGRESTE', 'Cemiterio')"
     />
    
   </v-col>
 
  
    </v-row>

    <v-row>
     
      <v-col cols="12">
        <v-card color="white" dark elevation="1" style="height: 450px !important;">
          <v-card-title class="text-h5 text--black" style="color: white;background-color: #2f54a4;">Totais Sepultados - 5 ANOS</v-card-title>
          <v-card-text>
            <!-- Adicione o gráfico aqui -->
            <apexcharts type="line" height="350" :options="generateLineChartOptions(gf_1_color, 400, 1700)" :series="gf_1_series"></apexcharts>
          </v-card-text>
        </v-card>
      </v-col>


    </v-row>

  </v-container>

</v-app> 
</template>
<script>
  import VueApexCharts from "vue-apexcharts";
  import axios from 'axios'
  import Sepultado from '../../services/sepultado'
  import FiltroComponent from "./components/Filtro.vue"; // Certifique-se de que o caminho esteja correto

  export default {
    name: 'Obitos',

    components: {
      apexcharts: VueApexCharts,
      FiltroComponent,

    },

    created() {
      this.selectedStartDate = this.getFirstDayOfYear(),
      this.selectedEndDate =  this.formatDateBR(new Date())
    },


    
    mounted() {
  
      this.selectedType = "Todos"
      this.selectedCompany = "Todas",

      this.apiExterna("AL", "")
      //this.apiExterna(this.selectedYear, "AL", ""); // Chame apiExterna com o ano selecionado
      this.apiExterna("Maceio", "&state=AL"); // Chame apiExterna com o ano selecionado
      this.gf01();
      this.dashboard();

    },
   

    data () {
      return {

         obitos_al:0,
         obitos_a:0,
         obitos_m:0,
         obitos_p:0,

         cemiterio_previda: 0,
         parque_das_flores: 0,
         memorial_parque_maceio: 0,
         parque_agreste: 0,
         funeraria_previda: 0,
         showProgress: false,

          empresa: 'Todas',
          selectedCompany: "Todas",
          selectedType: "Todos",
          selectedOpcao: "",
          selectMode: "",
          selectedStartDate : "",
          selectedEndDate : "",
          isLoading: false,
          opcoes: [],
          modos: [],
          showFilter: false,
          empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PREVIDA", "A FLOR DO CAFÉ", "Todas"],
          tipos: ["Todos"],

          tipos_funerarios: [
                "Todos",
              ],

         gf_1_color: [], // Azul e amarelo
         gf_1_series: [],

        }
    },

   
    methods: {
     


      filterData(valores) {
         this.isLoading = true;
         let nova_consulta = false;

          this.selectedType = valores.selectedType === "Jazigo" ? "Cemitérios" : valores.selectedType;
          this.selectedOpcao = valores.selectedOpcao;
          this.selectedCompany = valores.selectedCompany.join(",");
          this.selectMode = valores.selectMode;
          this.selectedStartDate = valores.selectedStartDate;
          this.selectedEndDate = valores.selectedEndDate;


       // this.apiExterna("AL", ""); // Chame apiExterna com o ano selecionado
       // this.apiExterna("Maceio", "&state=AL"); // Chame apiExterna com o ano selecionado

          this.apiExterna("AL", "")
          //this.apiExterna(this.selectedYear, "AL", ""); // Chame apiExterna com o ano selecionado
          this.apiExterna("Maceio", "&state=AL"); // Chame apiExterna com o ano selecionado
          this.gf01();
          this.dashboard();
      },

     
      getFirstDayOfYear() {
          const dataAtual = new Date();
          const primeiroDiaDoAno = new Date(dataAtual.getFullYear(), 0, 1); // Janeiro é representado como 0
          return this.formatDateBR(primeiroDiaDoAno);
        },

        handleDownload() {
          console.log("Download iniciado");
        },


        formatDateBR(date) {
                if (!date) return '';
                const parsedDate = new Date(date);
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const year = parsedDate.getFullYear();
                return `${day}/${month}/${year}`;
            },

        formatDateBRAPI(date) {
                if (!date) return '';
                const parsedDate = new Date(this.formatDateBR(date));
                const year = parsedDate.getFullYear();
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                return `${year}-${month}-${day}`;
              },

        async apiExterna(valor, complemento) {
                const dI = converteDataParaFormatoISO(this.selectedStartDate);
                const dF = converteDataParaFormatoISO(this.selectedEndDate);

                Sepultado.sepultamento_obitos_portal(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), complemento).then(response => {

                  if(response.status === 200){
                      if (response.data && response.data.data) {
                          const data = response.data.data;


                          if (valor === "Maceio") {
                              const vl_m = data.find(item => item.name === "Maceio");
                              const vl_a = data.find(item => item.name === "Arapiraca");
                              const vl_p = data.find(item => item.name === "Palmeira dos Índios");

                              const totalm = vl_m ? vl_m.total : 0;
                              const totala = vl_a ? vl_a.total : 0;
                              const totalp = vl_p ? vl_p.total : 0;

                              this.obitos_a = totala;
                              this.obitos_m = totalm;
                              this.obitos_p = totalp;

                          } else if (valor === 'AL') {
                              const vl = data.find(item => item.name === "AL");
                              const totalAL = vl ? parseInt(vl.total) : 0;
                              this.obitos_al = totalAL;
                          }
                      } else {
                          // Se não encontrar dados, atribua 0
                          this.obitos_a = 0;
                          this.obitos_m = 0;
                          this.obitos_p = 0;
                          this.obitos_al = 0;
                      }   
                  } 

                  }).catch(e => {    
                    this.obitos_a = 0;
                    this.obitos_m = 0;
                    this.obitos_p = 0;
                    this.obitos_al = 0;

                  }).finally(() => {
                 
                  })  

                
      },

      download_xls(empresas, tipo) {
            console.log(empresas)
            console.log(this.selectedCompany)

         
            this.showProgress = true;     
            
            const empresasArray = empresas.split(',').map(empresa => empresa.trim());
            const empresasFiltradas = empresasArray.filter(empresa => this.selectedCompany.includes(empresa));

            console.log("Empresas filtradas:", empresasFiltradas);
            let empresasString = empresasFiltradas.join(',');

            if(this.selectedCompany.includes("Todas")){
                empresasString = this.selectedCompany;
            }


            Sepultado.lista_sepultamento_xls(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), empresasString, tipo).then(response => {
                      if (response.status === 200) {
                      
                              const fileName = `sepultado${Date.now()}.xlsx`;
                              const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                              const fileLink = document.createElement('a')
                              fileLink.href = fileURL
                              fileLink.setAttribute('download', fileName)
                              document.body.appendChild(fileLink)
                              fileLink.click()
                          
                      }
                    })
                    .catch(error => {
                      // Lida com erros
                      console.error('Erro ao baixar o arquivo Excel:', error);
                    }) .finally(() => {
                      // Após o término do download, oculte o diálogo de progresso
                      this.showProgress = false;
                    });
      },

      

      async dashboard() {

                      this.cemiterio_previda = 0,
                      this.parque_das_flores = 0,
                      this.memorial_parque_maceio = 0,
                      this.parque_agreste = 0,

                    Sepultado.sepultameto_empresa(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedCompany).then(response => {
                  

                          if(response.status === 200){
                            this.calcularTotalAcumulado(response.data);    
                          } 

                        }).catch(e => {    
                        }).finally(() => {
                      })  

                      
                    Sepultado.sepultameto_funeraria_previda(converteDataParaFormatoISO(this.selectedStartDate), converteDataParaFormatoISO(this.selectedEndDate), this.selectedType, this.selectedCompany).then(response => {

                        if(response.status === 200){
                          this.calcularTotalAcumuladoFuneraria(response.data);    
                        } 

                        }).catch(e => {    
                        }).finally(() => {
                      })  

                      Sepultado.sepultamento_empresa_periodo('2021-01-01', '2025-12-31', this.selectedCompany).then(response => {

                          if(response.status === 200){
                            this.filtrarTotalGraficos(response.data, "Todos", "Todos", "Todas", "label", "qtd", "1");                      
                          } 

                          }).catch(e => {    
                          }).finally(() => {
                          })  

                        },
    
      calcularTotalAcumuladoFuneraria(lista) {

        this.funeraria_previda = 0;

          for (const item of lista) {

            if (item.label === 'PREVIDA') {

              this.funeraria_previda += item.qtd;
            }
          }



          },

          filtrarTotalGraficos(lista, adesao, tipo, nome_empresa, campoLabel, campoSerie, gf) {
          let totalAcumulado = 0;

          const newLabels = [];
          const newSeries = [];


          if (adesao === "Todos") {
            adesao = null;
          }
          if (tipo === "Todos") {
            tipo = null;
          }
          
          for (const item of lista) {
            const empresasSplit = nome_empresa.split(',');
            const empresas = empresasSplit.map(e => e.trim());
            const empresaIgual = empresas.includes(item.empresa) || empresas.includes("TODAS") || empresas.includes("Todas");
            const adesaoIgual = !adesao || item.adesao === adesao
            const tipoIgual = !tipo || item.tipo === tipo;

             
             
              if (adesaoIgual && tipoIgual && empresaIgual) {
                  newLabels.push(item[campoLabel]);
                  newSeries.push(item[campoSerie]);
              }
            }

            if(gf=== '1'){

                    // Itere sobre os valores e popule as séries correspondentes
                  for (let i = 0; i < newLabels.length; i++) {
                  
                    const nomeEmpresa = newLabels[i];
                    const valorEmpresa = newSeries[i];
                    const index = this.gf_1_series.findIndex((serie) => serie.name === nomeEmpresa);


                    // Se encontrar a série correspondente, adicione o valor apropriado aos dados
                    if (index !== -1) {
                      this.gf_1_series[index].data.push(valorEmpresa);
                    }
                  }

            }
            
      },


      calcularTotalAcumulado(lista) {

          for (const item of lista) {

            if (item.empresa === 'MEMORIAL PARQUE MACEIÓ') {
              this.memorial_parque_maceio += item.qtd;
            }
            if (item.empresa === 'PARQUE DO AGRESTE') {
              this.parque_agreste += item.qtd;
            }
            if (item.empresa === 'CEMITERIO PREVIDA') {
              this.cemiterio_previda += item.qtd;
            }
            if (item.empresa === 'PARQUE DAS FLORES') {
              this.parque_das_flores += item.qtd;
            }

          }

      },


      gf01(){
                      this.gf_1_series = [
                      {
                        name: "MEMORIAL PARQUE MACEIÓ",
                        data: [],
                        color: '#008300',
                        
                      },
                      {
                        name: "CEMITERIO PREVIDA",
                        data: [],
                        color: '#0000FF',
                      },

                      {
                        name: "PARQUE DO AGRESTE",
                        data: [],
                        color: '#FFA500',
                      },

                      {
                        name: "PARQUE DAS FLORES",
                        data: [],
                        color: '#82909b',
                      }

                    ]
            
        },
        clear () {
          this.$refs.form.reset();
        },

        generateLineChartOptions(colors, altura, vlMax) {
                return {
                    chart: {
                        height: altura,
                        type: 'line',
                        dropShadow: {
                          enabled: true,
                          color: '#000',
                          top: 18,
                          left: 7,
                          blur: 10,
                          opacity: 0.2
                        },
                        toolbar: {
                          show: true
                        }
                      },
                      dataLabels: {
                        enabled: true,
                        enabledOnSeries: [0, 1], // Índices das séries às quais aplicar rótulos
                        style: {
                          fontSize: '12px',
                          colors: ['#000']
                        }
                      },
                      colors: ['#77B6EA', '#545454'],
                      dataLabels: {
                        enabled: true,
                      },
                      stroke: {
                        curve: 'smooth'
                      },
                      title: {
                       // text: 'Average High & Low Temperature',
                        align: 'left'
                      },
                      grid: {
                        borderColor: '#e7e7e7',
                        row: {
                          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                          opacity: 0.5
                        },
                      },
                      markers: {
                        size: 1
                      },
                      xaxis: {
                        categories: ['2021', '2022', '2025','2024', '2025'],
                        title: {
                          text: 'Anos'
                        }
                      },
                      yaxis: {
                        title: {
                          text: ''
                        },
                        min: 0,
                        max: vlMax
                      },
                      legend: {
                        position: 'top',
                        horizontalAlign: 'right',
                        floating: true,
                        offsetY: -25,
                        offsetX: -5
                      }
                };
              }

     
    },
  }

  function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            }
</script>
